import { ActiveState } from './ActiveState'
import { LogLevel } from './LogLevel'
import { ParameterMap, ParameterValue } from './ParameterMap'

/**
 * Provides component wide ability to make a backend service active or inactive, and set
 * the log level.
 */
export class BackendParameterResponse {
  readonly data: ParameterMap

  private constructor (data: ParameterMap) {
    this.data = Object.assign({}, data)
  }

  private static readonly DEFAULTS_BACKEND_PARAMETERS = {
    'active.state': 'active',
    'log.level': 'info'
  }

  public static defaults (): BackendParameterResponse {
    return new BackendParameterResponse(BackendParameterResponse.DEFAULTS_BACKEND_PARAMETERS)
  }

  public assign (data: ParameterMap): BackendParameterResponse {
    Object.assign(this.data, data)
    return this
  }

  public get componentActive (): ActiveState {
    return this.data['active.state'] as ActiveState
  }

  public get logLevel (): LogLevel {
    return this.data['log.level'] as LogLevel
  }

  public parameter (key: string): ParameterValue {
    return this.data[key]
  }
}
