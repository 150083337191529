import { ParameterMap, ParameterValue } from './ParameterMap'

/**
 * Deployment configuration are built by combining multiple layers
 */
export class LayeredConfigurationResponse {
  readonly data: ParameterMap

  private constructor (data: ParameterMap) {
    this.data = Object.assign({}, data)
  }

  public static create (): LayeredConfigurationResponse {
    return new LayeredConfigurationResponse({})
  }

  public assign (data: ParameterMap): LayeredConfigurationResponse {
    Object.assign(this.data, data)
    return this
  }

  public parameter (key: string): ParameterValue {
    return this.data[key]
  }
}
