import { AvailabilityState } from './AvailabilityState'
import { ParameterValue } from './ParameterMap'

/**
 * Provides component wide ability to put an user interface in maintenance mode, or to limit
 * access to an application while validation takes place.
 */
export class UIParameterResponse {
  readonly data: { [key: string]: string }

  private constructor (data: { [key: string]: string }) {
    this.data = Object.assign({}, data)
  }

  private static readonly DEFAULTS_UI_PARAMETERS = {
    'availability.state': 'open',
    'availability.message': '',
    'availability.users': ''
  }

  public static defaults (): UIParameterResponse {
    return new UIParameterResponse(UIParameterResponse.DEFAULTS_UI_PARAMETERS)
  }

  public assign (data: { [key: string]: string }): UIParameterResponse {
    Object.assign(this.data, data)
    return this
  }

  public get availability (): AvailabilityState {
    return this.data['availability.state'] as AvailabilityState
  }

  public get message (): string {
    return this.data['availability.message']
  }

  public get limitedUsers (): string[] {
    return this.data['availability.users'].split(',').filter((user: string) => { return user !== '' })
  }

  public parameter (key: string): ParameterValue {
    return this.data[key]
  }
}
