import { ConfigBuilder } from './ConfigBuilder'
import { PrivateClient } from './PrivateClient'
import { QueryError } from './QueryError'
import { LayeredConfigurationResponse } from './LayeredConfigurationResponse'

export class DeployConfiguration$Builder extends ConfigBuilder<DeployConfiguration> {
  constructor () {
    super('deploy')
  }

  build (): DeployConfiguration {
    return new DeployConfiguration(PrivateClient.create({ profile: this._profile }), this)
  }
}

export class DeployConfiguration {
  readonly privateClient: PrivateClient
  readonly config: DeployConfiguration$Builder

  constructor (privateClient: PrivateClient, config: DeployConfiguration$Builder) {
    this.privateClient = privateClient
    this.config = config
  }

  async queryLayeredConfiguration (): Promise<LayeredConfigurationResponse> {
    if (this.config._stage === undefined || this.config._awsRegion === undefined || this.config._layers.length === 0) {
      throw new QueryError('[stage, awsRegion, layers] are required parameters for this query')
    }

    // Merge down all the layers
    const result = LayeredConfigurationResponse.create()
    try {
      for (const key of this.config._layers) {
        const parameter = `/${this.config._stage}/${this.config._awsRegion}/${this.config._scope}/defaults/${key}`
        result.assign(await this.privateClient.queryParameter(this.config._awsRegion, parameter))
      }
    } catch (error) {
      throw new QueryError('Could not load specified layers')
    }

    return result
  }
}
