import { RuntimeParameters$Builder } from './RuntimeParameters'
import { DeployConfiguration$Builder } from './DeployConfiguration'

/**
 * Class to load configuration from DNS and SSM services.
 */
export const AtlasConfig = {
  /**
   * Build Runtime parameter queries.
   * @return
   */
  forRuntime (): RuntimeParameters$Builder {
    return new RuntimeParameters$Builder()
  },

  /**
   * Build Deploy parameter queries.
   * @return
   */
  forDeploy (): DeployConfiguration$Builder {
    return new DeployConfiguration$Builder()
  }
}
