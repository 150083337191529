import { ConfigBuilder } from './ConfigBuilder'
import { PublicClient } from './PublicClient'
import { PrivateClient } from './PrivateClient'
import { ActiveRegionResponse } from './ActiveRegionResponse'
import { InternalRegionResponse } from './InternalRegionResponse'
import { UIParameterResponse } from './UIParameterResponse'
import { BackendParameterResponse } from './BackendParameterResponse'
import { QueryError } from './QueryError'

export class RuntimeParameters$Builder extends ConfigBuilder<RuntimeParameters> {
  constructor () {
    super('runtime')
  }

  build (): RuntimeParameters {
    return new RuntimeParameters(
      PublicClient.create({ protocol: this._queryWith, stage: this._stage }),
      PrivateClient.create({ profile: this._profile }), this)
  }
}

export class RuntimeParameters {
  readonly publicClient: PublicClient
  readonly privateClient: PrivateClient
  readonly config: RuntimeParameters$Builder

  constructor (publicClient: PublicClient, privateClient: PrivateClient, config: RuntimeParameters$Builder) {
    this.publicClient = publicClient
    this.privateClient = privateClient
    this.config = config
  }

  /**
   * Get the currently active region, see ActiveRegionResponse for full details.
   * @return
   * @throws QueryError
   */
  async queryActiveRegion (): Promise<ActiveRegionResponse> {
    if (this.config._stage === undefined) {
      throw new QueryError('[stage] are required parameters for this query')
    }
    // DNS Query
    let result: ActiveRegionResponse | undefined
    try {
      const response = await this.publicClient.queryParameter('active-region')
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (response !== undefined && response['active.region'] !== undefined && response['failover.state'] !== undefined &&
        response['primary.authentication'] !== undefined && response['primary.api'] !== undefined && response['primary.websocket'] !== undefined &&
        response['secondary.authentication'] !== undefined && response['secondary.api'] !== undefined && response['secondary.websocket'] !== undefined
      ) {
        result = ActiveRegionResponse.from(response)
      } else {
        throw new Error('All the required values were not returned')
      }
    } catch (error) {
      console.error(error)
    }

    // Falls back to defaults if failed
    if (result === undefined) {
      result = ActiveRegionResponse.defaults(this.config._stage)
    }
    return result
  }

  /**
   * Get the internal region config, see InternalRegionResponse for full details.
   * @return
   * @throws QueryError
   */
  async queryInternalRegion (): Promise<InternalRegionResponse> {
    if (this.config._stage === undefined || this.config._regionRole === undefined) {
      throw new QueryError('[stage, regionRole] are required parameters for this query')
    }
    // DNS Query
    let result: InternalRegionResponse | undefined
    try {
      const response = await this.publicClient.queryParameter('active-region')
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (response !== undefined && response['active.region'] !== undefined && response['failover.state'] !== undefined &&
        response['primary.authentication'] !== undefined && response['primary.api'] !== undefined && response['primary.websocket'] !== undefined &&
        response['secondary.authentication'] !== undefined && response['secondary.api'] !== undefined && response['secondary.websocket'] !== undefined
      ) {
        result = InternalRegionResponse.from(response, this.config._regionRole)
      } else {
        throw new Error('All the required values were not returned')
      }
    } catch (error) {
      console.error(error)
    }

    // Falls back to defaults if failed
    if (result === undefined) {
      result = InternalRegionResponse.defaults(this.config._stage, this.config._regionRole)
    }
    return result
  }

  /**
   * Get the ui component parameters, see UIParameterResponse for full details.
   * @return
   * @throws QueryError
   */
  async queryUiParameters (): Promise<UIParameterResponse> {
    if (this.config._stage === undefined || this.config._application === undefined || this.config._component === undefined || this.config._version === undefined) {
      throw new QueryError('[stage, application, component, version] are required parameters for this query')
    }

    // Falls back to defaults if error occurs
    const result: UIParameterResponse = UIParameterResponse.defaults()
    try {
      // DNS Query
      const componentFqn: string = `${this.config._application}-${this.config._component}-${this.config._version}`
      result.assign(await this.publicClient.queryParameter(`${componentFqn}-ui-parameters`))
    } catch (error) {
      console.error(error)
    }

    return result
  }

  async queryBackendParameter (): Promise<BackendParameterResponse> {
    if (this.config._stage === undefined || this.config._awsRegion === undefined || this.config._application === undefined || this.config._component === undefined || this.config._version === undefined) {
      throw new QueryError('[stage, awsRegion, application, component, version] are required parameters for this query')
    }

    console.log('config', this.config)

    // Falls back to defaults if error occurs
    const result = BackendParameterResponse.defaults()
    try {
      // Merge down all the layers
      for (const key of this.config._layers) {
        const parameter = `/${this.config._stage}/${this.config._awsRegion}/${this.config._scope}/defaults/${key}`
        result.assign(await this.privateClient.queryParameter(this.config._awsRegion, parameter))
      }
    } catch (error) {
      throw new QueryError('Could not load specified defaults')
    }
    console.log('layers', result)

    // Merge down the specific component config
    try {
      const parameter = `/${this.config._stage}/${this.config._awsRegion}/${this.config._scope}/${this.config._application}-${this.config._component}-${this.config._version}`
      result.assign(await this.privateClient.queryParameter(this.config._awsRegion, parameter))
    } catch (e) {
      console.log('Only using defaults')
    }
    console.log('result', result)

    return result
  }
}
