import { FailoverState } from './FailoverState'
import { RegionRole } from './RegionRole'
import { DeployStage } from './DeployStage'

/**
 * Represents the currently active region configuration. Applications will be provided with credentials for both primary
 * and secondary regions; these clients should use the respective client credentials based on the 'activeRegion' field.
 * The 'authenticationUrl', 'apiUrl' and 'websocketUrl' fields will return the value of the currently active region.
 *
 * Additionally if clients are using the 'Authorization code grant' flow, separate refresh tokens needs to be maintained
 * for primary and secondary regions. See:
 * https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-app-idp-settings.html
 *
 * These values should NOT be used by API clients such as lambda's which are inherently regional and internally always
 * connects to other services in the same region. It is intended to be used by clients such as mobile applications and
 * external applications which always need to connect to the region currently active.
 */
export class ActiveRegionResponse {
  readonly activeRegion: RegionRole
  readonly failoverState: FailoverState
  readonly authenticationUrl: string
  readonly apiUrl: string
  readonly websocketUrl: string

  private constructor (data: { [key: string]: string }) {
    this.activeRegion = data['active.region'] as RegionRole
    this.failoverState = data['failover.state'] as FailoverState
    this.authenticationUrl = 'https://' + data[`${this.activeRegion}.authentication`]
    this.apiUrl = 'https://' + data[`${this.activeRegion}.api`]
    this.websocketUrl = 'wss://' + data[`${this.activeRegion}.websocket`]
  }

  static DEFAULTS_REGION_MASTER = {
    'active.region': 'primary',
    'failover.state': 'stable',
    'primary.authentication': 'atlas-auth.mit.edu',
    'primary.api': 'api.mit.edu',
    'primary.websocket': 'wss-api.mit.edu',
    'secondary.authentication': 'atlas-auth-us-west.mit.edu',
    'secondary.api': 'api-us-west.mit.edu',
    'secondary.websocket': 'wss-api-us-west.mit.edu'
  }

  static DEFAULTS_REGION_RELEASE = {
    'active.region': 'primary',
    'failover.state': 'stable',
    'primary.authentication': 'atlas-auth-test.mit.edu',
    'primary.api': 'api-test.mit.edu',
    'primary.websocket': 'wss-api-test.mit.edu',
    'secondary.authentication': 'atlas-auth-us-west-test.mit.edu',
    'secondary.api': 'api-us-west-test.mit.edu',
    'secondary.websocket': 'wss-api-us-west-test.mit.edu'
  }

  static DEFAULTS_REGION_DEVELOP = {
    'active.region': 'primary',
    'failover.state': 'stable',
    'primary.authentication': 'atlas-auth-dev.mit.edu',
    'primary.api': 'api-dev.mit.edu',
    'primary.websocket': 'wss-api-dev.mit.edu',
    'secondary.authentication': 'atlas-auth-us-west-dev.mit.edu',
    'secondary.api': 'api-us-west-dev.mit.edu',
    'secondary.websocket': 'wss-api-us-west-dev.mit.edu'
  }

  public static from (data: { [key: string]: string }): ActiveRegionResponse {
    return new ActiveRegionResponse(data)
  }

  public static defaults (stage: DeployStage): ActiveRegionResponse {
    let response: ActiveRegionResponse
    if (stage === 'master') {
      response = ActiveRegionResponse.from(ActiveRegionResponse.DEFAULTS_REGION_MASTER)
    } else if (stage === 'release') {
      response = ActiveRegionResponse.from(ActiveRegionResponse.DEFAULTS_REGION_RELEASE)
    } else if (stage === 'develop') {
      response = ActiveRegionResponse.from(ActiveRegionResponse.DEFAULTS_REGION_DEVELOP)
    } else {
      response = ActiveRegionResponse.from(ActiveRegionResponse.DEFAULTS_REGION_MASTER)
    }
    return response
  }
}
