import { FailoverState } from './FailoverState'
import { RegionRole } from './RegionRole'
import { DeployStage } from './DeployStage'
import { ActiveRegionResponse } from './ActiveRegionResponse'

/**
 * Represents the current state ('active' field) of a region and applications may decide to turn off / on functionality
 * based on this state. The relevant URLs will be returned in 'authenticationUrl', 'apiUrl' and 'websocketUrl' and should
 * be used with the provided credentials for that region.
 *
 * These values should be used by API clients such as lambda's which are inherently regional and internally always
 * connects to other services in the same region. This could also be used by external clients for which it is acceptable to
 * be down during the time of the primary region being inactive.
 */
export class InternalRegionResponse {
  readonly regionActive: boolean
  readonly failoverState: FailoverState
  readonly authenticationUrl: string
  readonly apiUrl: string
  readonly websocketUrl: string

  private constructor (data: { [key: string]: string }, region: RegionRole) {
    this.regionActive = region === data['active.region']
    this.failoverState = data['failover.state'] as FailoverState
    this.authenticationUrl = 'https://' + data[`${region}.authentication`]
    this.apiUrl = 'https://' + data[`${region}.api`]
    this.websocketUrl = 'wss://' + data[`${region}.websocket`]
  }

  public static from (data: { [key: string]: string }, region: RegionRole): InternalRegionResponse {
    return new InternalRegionResponse(data, region)
  }

  public static defaults (stage: DeployStage, region: RegionRole): InternalRegionResponse {
    let response: InternalRegionResponse
    if (stage === 'master') {
      response = InternalRegionResponse.from(ActiveRegionResponse.DEFAULTS_REGION_MASTER, region)
    } else if (stage === 'release') {
      response = InternalRegionResponse.from(ActiveRegionResponse.DEFAULTS_REGION_RELEASE, region)
    } else if (stage === 'develop') {
      response = InternalRegionResponse.from(ActiveRegionResponse.DEFAULTS_REGION_DEVELOP, region)
    } else {
      response = InternalRegionResponse.from(ActiveRegionResponse.DEFAULTS_REGION_MASTER, region)
    }
    return response
  }
}
