/**
 * This file is preprocessed to add build specific functionality for browser and nodejs
 * environments, the original file lives under the `PROJECT/hbs` folder.
 */
import { ParameterMap } from './ParameterMap'
import { QueryError } from './QueryError'
/* Node environment only. */

export interface PrivateClientOptions {
  readonly profile?: string
  readonly inBrowser?: boolean
}

export abstract class PrivateClient {
  readonly profile: string

  constructor (profile: string) {
    this.profile = profile
  }

  static create (options: PrivateClientOptions): PrivateClient {
    let client: PrivateClient | undefined

    /* Node environment only. */

    // Fallback to error client
    if (client === undefined) {
      client = new PrivateClientErrorImpl(options.profile ?? 'default')
    }

    return client
  }

  public abstract queryParameter (awsRegion: string, parameter: string): Promise<ParameterMap>

  public abstract querySecret (awsRegion: string, secretName: string): Promise<ParameterMap>
}

export class PrivateClientErrorImpl extends PrivateClient {
  public async queryParameter (awsRegion: string, key: string): Promise<ParameterMap> {
    console.error('Application could not load required config client.')
    throw new QueryError('Query could not completed, application configuration error.')
  }

  public async querySecret (awsRegion: string, name: string): Promise<ParameterMap> {
    console.error('Application could not load required config client.')
    throw new QueryError('Query could not completed, application configuration error.')
  }
}

/* Node environment only. */
